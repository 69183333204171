import * as actionTypes from '../type';

const initialState = {
    statsEmi: {},
    statsEmiOverview: {},
    statsSelfEmiOverview:{},
    loaded: false,
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_STATS_EMI:
            return { ...state, statsEmi: action.payload };
        case actionTypes.SET_STATS_EMI_OVERVIEW:
            return { ...state, statsEmiOverview: action.payload };
        case actionTypes.SET_STATS_SELF_EMI_OVERVIEW:
            return { ...state, statsSelfEmiOverview: action.payload };
        case actionTypes.SET_EMI_LOADED:
            return { ...state, loaded: action.payload };
    
        default:
            return state;
    }
}

export default reducer;
