import * as actionTypes from '../type';

const initialState = {
    airLeaderBoardState: {},
    topTenLeaderBoardState: {},
    getUserRankState: {},
    getLeaderBoardZonalRankState: {},
    getLeaderBoardCpList: {},
    getDubaiBoardQualifier : {}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_STATS_LEADER_BOARD_AIR:
            return {
                ...state, airLeaderBoardState: action.payload, loaded: true
            };

        case actionTypes.SET_STATS_LEADER_BOARD_TOP_TEN:
            return { ...state, topTenLeaderBoardState: action.payload, loaded: true };

        case actionTypes.SET_STATS_LEADER_BOARD_DUBAI_QUALIFIER:
                return { ...state, getDubaiBoardQualifier: action.payload, loaded: true };    

        case actionTypes.SET_STATS_GET_USER_RANK:
            return { ...state, getUserRankState: action.payload, loaded: true };

        case actionTypes.SET_STATS_LEADER_BOARD_ZR:
            return { ...state, getLeaderBoardZonalRankState: action.payload, loaded: true };

        case actionTypes.SET_STATS_CP_LIST:
            return { ...state, getLeaderBoardCpList: action.payload, loaded: true };


        default:
            return state;
    }
}

export default reducer;