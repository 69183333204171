import * as actionTypes from '../type';

const initialState = {
    webStatsTrendDau: {},
    getStatsFunnelDashoabrd: {},
    webStatsOverviewDau: {}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_STATS_TREND_DAU:
            return { ...state, webStatsTrendDau: action.payload };
        case actionTypes.SET_STATS_OVERVIEW_DAU:
            return { ...state, webStatsOverviewDau: action.payload };
        case actionTypes.SET_STATS_FUNNEL_DASHBOARD:
            return { ...state, getStatsFunnelDashoabrd: action.payload };
        default:
            return state;
    }
}

export default reducer;
