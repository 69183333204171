import React, { useEffect, useState } from 'react'
import './style.css';
import './media.css';
import queryString from 'query-string';
import { getBbCoinsHistory } from '../../http/ServiceApi';
import { BB_COINS_HISTORY } from '../../store/type';
import { useDispatch, useSelector } from 'react-redux';
import { setToken } from "../../http/Axios";
import { useHistory } from "react-router-dom";

export default function PointHistory(props) {

    const params = queryString.parse(props.location.search);
    const dispatch = useDispatch();
    const token = params.token;
    const UserCoin = localStorage.getItem("UserCoin")
    const apiData = useSelector(store => store?.coins);
    const history = useHistory();
    const [getKeys, setGetKeys] = useState([])
    const [status, setStatus] = useState("")


    useEffect(() => {
        if (apiData?.getBBCoinsHistory.length !== 0) {

            setGetKeys(Object.keys(apiData?.getBBCoinsHistory))
        }

    }, [apiData])

    useEffect(() => {
        const CoinsHistory = async () => {
            const getCoinsHistory = await getBbCoinsHistory();
            if (getCoinsHistory.isSucces) {
                if (getCoinsHistory?.data?.length === 0) {
                    setStatus("No Data Found")
                } else {
                    const historyData = makeJson(getCoinsHistory.data)
                    dispatch({ type: BB_COINS_HISTORY, payload: historyData });
                }

            }
        }
        if (params.token) {
            setToken(token);
            CoinsHistory();
        }
        else {
            props?.history?.push('error');
        }

    }, [])

    const makeJson = (data) => {

        const result = Array.from(new Set(data.map(s => s.groupLabel)))
            .map(lab => {
                return {
                    [lab]: data.filter(s => s.groupLabel === lab).map(edition => edition)
                }
            })

        return Object.assign({}, ...result)
    }

    return (
        <>
            <section className="coin_main">
                <div className="top_back"> <i className="fa fa-angle-left" onClick={() => history.goBack()} aria-hidden="true"></i> &nbsp; <span>Coins History </span></div>
                <p className="text-center mt-2" >
                    <img src="https://images.badabusiness.com/bbapp/images/bb-coins/coins_img.png" className="" alt="" />
                </p>
                <div className="coin_center">
                    <div className="coin_score">
                        <span>{UserCoin?.toLocaleString('en-US')} </span>
                        <p>Coins</p>
                    </div>
                    <p>Available BB Coins balance: Rs {UserCoin?.toLocaleString('en-US')}</p>
                </div>
            </section>


            {
                status !== "" ? 
                <>
                    <div className='text-center mt-5'>
                        <b>{status}</b>
                    </div>
                </> :
                    getKeys?.map((item) => {

                        return (
                            <section className="cardview  mt-3 coin_des">
                                <div className="container-fluid">
                                    <div className="heading">{item}</div>
                                    {
                                        apiData?.getBBCoinsHistory[item].map((innerCard, index) => {
                                            return <div className="innrcardview">
                                                <div className="card_body">
                                                    <div className="row p-0 m-0">
                                                        <div className="col-md-3 col-md-3 col-3 m-0 pb-2 text-center">
                                                            <img src={innerCard?.icon} alt="" className="img_scale" />
                                                        </div>
                                                        <div className="col-md-5 col-md-5 col-5 m-0 p-0">
                                                            <h5>{innerCard?.title}</h5>

                                                            <span className="time"> {innerCard?.transactionDate} </span>
                                                        </div>
                                                        <div className="col-md-4 col-md-4 col-4 m-0 p-0 text-center">
                                                            <small className="coins text-right" style={{ "marginRight": "10px" }}> <b>{innerCard?.transactionType === "credit" ? "+" : "-"}{innerCard?.coins?.toLocaleString('en-US')} Coins </b> </small>
                                                            <small className="clb" style={{ "marginRight": "10px" }}>Closing Balance</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </section>

                        )
                    })}

        </>
    )
}
