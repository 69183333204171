import { createStore, combineReducers, compose } from 'redux';
import daumau from './reducer/daumau';
import webinar from './reducer/webinar';
import revenu from './reducer/revenu';
import lead from './reducer/lead';
import potentialCommission from './reducer/potentialCommission';
import emi from './reducer/emi';
import ibcOnboard from './reducer/ibcOnboard';
import leaderBoard from './reducer/leaderBoard'
import communityPost from './reducer/communityPost'
import profile from './reducer/profile'
import coins from './reducer/coins'



const rootReducer = combineReducers({ coins:coins,daumau:daumau, webinar:webinar, revenu: revenu, lead:lead, potentialCommission: potentialCommission, emi: emi, ibcOnboard: ibcOnboard,leaderBoard:leaderBoard ,communityPost:communityPost,profile:profile});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers());

export default store;
