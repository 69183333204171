import React, { useEffect, useState } from 'react'
import './style.css';
import './media.css';
import queryString from 'query-string';
import { getRedeemHistory } from '../../http/ServiceApi';
import { useDispatch, useSelector } from 'react-redux';
import { BB_COINS_HISTORY } from '../../store/type';
import { setToken } from "../../http/Axios";
import { useHistory } from 'react-router-dom';

export default function RedeemHistory(props) {

    const [status, setStatus] = useState("")

    let params = queryString.parse(props.location.search);
    let token = params.token;
    let dispatch = useDispatch();
    let apiData = useSelector(store => store?.coins);
    const history = useHistory();


    useEffect(() => {
        const redeemHistory = async () => {
            const getCoinsHistory = await getRedeemHistory();
            if (getCoinsHistory.isSucces) {
                if (getCoinsHistory?.data?.length === 0) {
                    setStatus("No Data Found")
                } else {
                    dispatch({ type: BB_COINS_HISTORY, payload: getCoinsHistory.data });
                }
            }
        }
        if (params.token) {
            setToken(token);
            redeemHistory();
        }
        else {
            props?.history?.push('error');
        }
    }, [])

    return (
        <>
            <div class="top-band">
                <div className="top_back"> 
               <div className='BackIcon'> <i class="fa fa-angle-left" onClick={() => history.goBack()} aria-hidden="true"></i> &nbsp;Redeem History</div>
                </div>
            </div>
            {
                status !== "" ?
                    <>
                        <div className='text-center mt-5'>
                            <b>{status}</b>
                        </div>
                    </> :

                    apiData?.getBBCoinsHistory?.map((item) => {
                        return (
                            <section className="cardview redeem_his_gap">
                                <div className="card_body">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-3 col-sm-3 col-3 text-center"> <img src={item?.offerIcon} alt="" className="img_scale" /></div>
                                            <div className="col-md-5 col-md-5 col-5">
                                                <h4>{item?.title}<br />
                                                    <span>{item?.redeemCoins?.toLocaleString('en-US')} Coins</span>
                                                </h4>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <h6 className="text-center">{item?.redeemDate}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )
                    })}
        </>
    )
}
