import * as actionTypes from '../type';

const initialState = {
    webStatsWebinar: {},
    webStatsWebinarDaily: {},
    webStatsWebinarSource: {}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_STATS_WEBINAR_SOURCE:
            return { ...state, webStatsWebinarSource: action.payload };
        case actionTypes.SET_STATS_WEBINAR_DAILY:
            return { ...state, webStatsWebinarDaily: action.payload };
        case actionTypes.SET_STATS_WEBINAR_DATA:
            return { ...state, webStatsWebinar: action.payload };
        default:
            return state;
    }
}

export default reducer;
