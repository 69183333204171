import * as actionTypes from '../type';

const initialState = {
    getBBCoins: {},
    getBBCoinsBanner: [],
    getBBCurrentOffers: [],
    getBBUpComingOffers: [],
    getBBCoinsHistory: [],
    getOfferDetails: [],
    getRedeemCoupon: [],
    getRedeemCode : []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.BB_COINS:
            return { ...state, getBBCoins: action.payload, loaded: true };
        case actionTypes.BB_COINS_BANNER:
            return { ...state, getBBCoinsBanner: action.payload, loaded: true };
        case actionTypes.BB_COINS_CURRENT_OFFERS:
            return { ...state, getBBCurrentOffers: action.payload, loaded: true };
        case actionTypes.BB_COINS_UPCOMING_OFFERS:
            return { ...state, getBBUpComingOffers: action.payload, loaded: true };
        case actionTypes.BB_COINS_HISTORY:
            return { ...state, getBBCoinsHistory: action.payload, loaded: true };
        case actionTypes.BB_COINS_OFFERS_DETAILS:
            return { ...state, getOfferDetails: action.payload, loaded: true };
        case actionTypes.BB_COINS_COUPON_REDEEM:
            return { ...state, getRedeemCoupon: action.payload, loaded: true };
        case actionTypes.BB_COINS_REDEEM_CODE:
            return { ...state, getRedeemCode: action.payload, loaded: true };
        default:
            return state;
    }
}

export default reducer;