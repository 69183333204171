import React, { useEffect } from 'react'
import queryString from 'query-string';
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../../http/Axios";
import './style.css';
import './media.css';
import { BB_COINS_COUPON_REDEEM } from '../../store/type';
import { getRedeemCoupons } from '../../http/ServiceApi';

export default function RedeemCoupon(props) {
    let params = queryString.parse(props.location.search);
    let token = params.token;
    let newToken = token?.split("/?")
    let offerId = newToken[1]?.split("=")[1]
    const dispatch = useDispatch();
    const apiData = useSelector(store => store?.coins);

    useEffect(() => {
        const redeemCoupons = async () => {
            const payload = {
                offerId: offerId
            }
            const getCouponData = await getRedeemCoupons(payload);
            if (getCouponData.isSucces) {
                dispatch({ type: BB_COINS_COUPON_REDEEM, payload: getCouponData.data });
            }
        }
        if (params.token) {
            setToken(newToken[0]);
            redeemCoupons();
        }
        else {
            props?.history?.push('error');
        }
    }, [])

    return (
        <>
            {/* {apiData?.getRedeemCoupon?.map((item) => { */}
                {/* return ( */}
                    {/* <> */}
                        <div className="container-fluid mx-auto">
                            <div className="row justify-content-between gap_coupon_box">
                                <div className="redeem_block text-center">
                                    <div className="row">
                                        <div className="col-sm-3 col-3"><img src={apiData?.getRedeemCoupon?.offerbanner} className="couponbox_img" /> </div>
                                        <div className="col-sm-6 col-6 p-0 m-0">
                                            <div className="offer">
                                                <div className="order_id">Order Id : {apiData?.getRedeemCoupon?.orderId}</div>
                                                <div className="name">{apiData?.getRedeemCoupon?.totalCoinsRedeem} Coins For {apiData?.getRedeemCoupon?.title}</div>
                                                <div className="discrip">{apiData?.getRedeemCoupon?.description}</div>
                                            </div>
                                        </div>
                                        {/* <div className="col-sm-3 col-3 ">
                                            <div className="date_cad">O7 May 23</div>
                                            <div className="terms">
                                                *T&C Apply
                                            </div>
                                        </div> */}
                                    </div>
                                </div>

                                {/* 
                                    <div className="redeem_card_app">
                                        <div className="row">
                                            <div className="col-sm-8 col-8 p-0 m-0"> 
                                                <img src="https://images.badabusiness.com/bbapp/images/bb-coins/goa.png" className="" /> &nbsp; Apply coupon
                                            </div>
                                            <div className="col-sm-4 col-4 p-0 m-0 text-right">
                                                <a href="#" className="reed_select">Select </a>
                                            </div>
                                        </div>
                                    </div> 
                                */}
                                <div className="redeem_card_app">
                                    <div className="row">
                                        <div className="col-sm-12 col-12">
                                            <p className="text-dark font-weight-bold">Total Coins Redeem</p>
                                        </div>
                                        <div className="col-sm-12 col-12">
                                            <p className="text-secondary">{apiData?.getRedeemCoupon?.totalCoinsRedeem} Coins</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <a href={`/congratulations-redeem-sucessfull/?token=${token}/?orderId=${apiData?.getRedeemCoupon?.orderId}`}>
                            <button type="button" className="btn btn_active_coup">Redeem</button>
                        </a>
                    {/* </> */}
                {/* ) */}
            {/* })} */}


        </>
    )
}
