import React, { Suspense } from 'react';
import './App.css';
import '../src/components/BBCoins/style.css'
import '../src/components/BBCoins/media.css'
import { Switch, Route, withRouter } from 'react-router-dom'
import PointHistory from './components/BBCoins/PointHistory';
import redeemHistory from './components/BBCoins/RedeemHistory';
import TmcClub from './components/BBCoins/TmcClub';
import RedeemCoupon from './components/BBCoins/RedeemCoupon'
import ThankYou from './components/BBCoins/ThankYou';

const HomePosts = React.lazy(() => import("./components/Community/HomePosts"));
const Dashboard = React.lazy(() => import("./pages/Dashboard/Dashboard"));
const AddPost = React.lazy(() => import("./components/Community/AddPost"));
const Board = React.lazy(() => import("./components/LeaderBoard/board"));
const BbCoins = React.lazy(() => import("./components/BBCoins/BbCoins"));
const WebinarDashboard = React.lazy(() => import("./components/WebinarDashboard/WebinarDashboard"));
const ClubDetails = React.lazy(() => import("./pages/ClubDetails/ClubDetails"));
const ViewMore = React.lazy(() => import("./components/ViewMore/ViewMore"));
const Error = React.lazy(() => import("./pages/Error/Error"));
const Webinar = React.lazy(() => import("./pages/Webinar/Webinar"));
const ElpRank = React.lazy(() => import("./components/ElpRanking/ElpRank"));
const HomeDashboard = React.lazy(() => import("./pages/Home/Index"));

const App = (props) => {

  return (
    <div className="app">
       {/* <ToastContainer /> */}
      {/* screen loader here*/}
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route exact path="/" component={Dashboard} />
          {/* <Route exact path="/posts" component={TestQa} /> */}
          <Route exact path="/webinar" component={Webinar} />
          <Route exact path="/viewmore" component={ViewMore} />
          <Route exact path="/webinar-dashboard" component={WebinarDashboard} />
          <Route exact path="/club" component={ClubDetails} />
          <Route exact path="/error" component={Error} />
          <Route exact path="/leaderBoard" component={Board} />
          <Route exact path="/posts" component={HomePosts} />
          <Route exact path="/elp-ranking" component={ElpRank} />
          {/* <Route exact path="/post/:id" component={Post} /> */}

          {/* BB Coins Routes  */}
          <Route exact path="/bb-coins" component={BbCoins} />
          <Route exact path="/point-history" component={PointHistory} />
          <Route exact path="/redeem-history" component={redeemHistory} />
          <Route exact path="/tmc-club" component={TmcClub} />
          <Route exact path="/redeem-coupon" component={RedeemCoupon} />
          <Route exact path="/congratulations-redeem-sucessfull" component={ThankYou} />
          

          <Route exact path="/addPost" component={AddPost} />
          <Route exact path="/home" component={HomeDashboard} />
        </Switch>
      </Suspense>

    </div>
  );
}

export default withRouter(App);
