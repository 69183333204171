import Axios from "./Axios";

export const statsRevenu = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(`/api/v1/webdashboard/stats-revenue`, data);
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }

  return result;
};

export const ibcRevenu = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(`/api/v1/webdashboard/ibc-revenue`, data);
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const ibcTrendRevenu = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(
      `/api/v1/webdashboard/web-stats-trend-revenue`,
      data
    );
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const webStatsTrendDau = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(
      `/api/v1/webdashboard/web-stats-trend-dau`,
      data
    );
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const webStatsOverviewDau = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(
      `/api/v1/webdashboard/web-stats-overview-dau`,
      data
    );
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const ibcRevenuOverview = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(
      `/api/v1/webdashboard/web-stats-revenue-overview`,
      data
    );
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const webStatsRevenueSubOverview = async (data = {}) => {
  console.log("datadata", data);
  let result = { isSucces: false };
  try {
    let res = await Axios.post(
      `/api/v1/webdashboard/web-stats-revenue-sub-overview`,
      data
    );
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const statsTrendRevenu = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(
      `/api/v1/webdashboard/stats-trend-revenue`,
      data
    );
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const statsRevenuOverview = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(
      `/api/v1/webdashboard/stats-revenue-overview`,
      data
    );
    result.isSucces = true;
    result.data = res.data.data[0];
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const getRole = async (token) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.get(`/api/v1/webdashboard/get-role`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const statsCpRevenu = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(`/api/v1/webdashboard/stats-cp-revenue`, data);
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const statsCpTrendRevenu = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(
      `/api/v1/webdashboard/stats-cp-trend-revenue`,
      data
    );
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const statsCpRevenuOverview = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(
      `/api/v1/webdashboard/stats-cp-revenue-overview`,
      data
    );
    result.isSucces = true;
    result.data = res.data.data[0];
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const statsCpIbcRevenuOverview = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(
      `/api/v1/webdashboard/stats-cp-ibc-revenue-overview`,
      data
    );
    result.isSucces = true;
    result.data = res.data.data[0];
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const statsTrendLead = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(`/api/v1/webdashboard/stats-trend-lead`, data);
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

// export const statsLeadOverview = async (data = {}) => {
//     let result = { isSucces: false }
//     try {
//         let res = await Axios.post(`/api/v1/webdashboard/stats-lead-overview`, data);
//         result.isSucces = true;
//         result.data = res.data.data;
//     } catch (error) {
//         console.log('error.response', error.response);
//         result.message = error?.response?.data;
//     }
//     return result;
// }

export const webStatsTrendLead = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(
      `/api/v1/webdashboard/web-stats-trend-lead`,
      data
    );
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const webStatsLeadOverview = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(
      `/api/v1/webdashboard/web-stats-lead-overview`,
      data
    );
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const statsSelfLeadOverview = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(
      `/api/v1/webdashboard/stats-self-lead-overview`,
      data
    );
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

// potential commission

export const statsTrendPotentialCommission = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(
      `/api/v1/webdashboard/stats-trend-potential-commission`,
      data
    );
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const webStatsTrendPotentialCommission = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(
      `/api/v1/webdashboard/web-stats-trend-potential-commission`,
      data
    );
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const webStatsPotentialCommissionOverview = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(
      `/api/v1/webdashboard/web-stats-potential-commission-overview`,
      data
    );
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }

  return result;
};

export const statsPotentialCommissionOverview = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(
      `/api/v1/webdashboard/stats-potential-commission-overview`,
      data
    );
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const statsSelfPotentialCommissionOverview = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(
      `/api/v1/webdashboard/stats-self-potential-commission-overview`,
      data
    );
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

// Emi Loan

export const statsEmi = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(`/api/v1/webdashboard/stats-emi`, data);
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const statsEmiOverview = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(`/api/v1/webdashboard/stats-emi-overview`, data);
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const statsSelfEmiOverview = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(
      `/api/v1/webdashboard/stats-self-emi-overview`,
      data
    );
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const loadMoreData = async (url, data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(`${url}`, data);
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const loadIbcOnboard = async () => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(`/api/v1/webdashboard/ibc-onboarded`);
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const leaderBoardAir = async (data) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(`/api/v1/leaderboard/all-india-rank`, data);
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const leaderBoardTopTen = async (data) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(`/api/v1/leaderboard/top-ten-rank`, data);
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const getUserRank = async (data) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(`/api/v1/leaderboard/get-user-rank`, data);
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const leaderBoardZr = async (data) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(`/api/v1/leaderboard/zonal-rank`, data);
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const leaderBoardCpList = async (data) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(`/api/v1/leaderboard/get-cp-list`, data);
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const getBbCoins = async (data) => {
  console.log(data);
  let result = { isSucces: false };
  try {
    let res = await Axios.post(`/api/v1/coin`, data);
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const getBbCoinsBanner = async (data) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.get(`/api/v1/coin/banners`, data);
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const getBbCurrentOffers = async (data) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.get(`/api/v1/coin/offers/current`, data);
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};
export const getBbUpComingOffers = async (data) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.get(`/api/v1/coin/offers/upcoming`, data);
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};
export const getBbCoinsHistory = async (data) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.get(`/api/v1/coin/history`, data);
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const getRedeemHistory = async (data) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.get(`/api/v1/coin/redeem-history`, data);
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const getofferDetails = async (data) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.get(`/api/v1/coin/offer-details/${data}`);
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const getRedeemCoupons = async (data) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(`/api/v1/coin/redeem-coin-display`, data);
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const getRedeemCode = async (data) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(`/api/v1/coin/coin-redeem`, data);
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const webStatsWebinar = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(`/api/v1/webdashboard/webinar-dashboard`, data);
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const webStatsWebinarDaily = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(
      `/api/v1/webdashboard/daily-webinar-dashboard`,
      data
    );
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const webStatsWebinarSource = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(`/api/v1/webdashboard/web-lead-source`, data);
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const getFunnelDashboard = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(
      `/api/v1/webdashboard/get-funnel-dashboard`,
      data
    );
    result.isSucces = true;
    result.data = res.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const addPost = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(`/api/v1/posts`, data);
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const viewPosts = async (page = 1) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.get(`/api/v1/posts?page=${page}`);
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.responses", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const likeUnlikePost = async (id = "") => {
  let result = { isSucces: false };
  try {
    let res = await Axios.get(`api/v1/posts/${id}/like`);
    result.isSucces = true;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.responses", error.response);
    result.message = error?.response?.data;
  }
  return result;
};
export const downloadPost = async (id = "") => {
  let result = { isSucces: false };
  try {
    let res = await Axios.get(`api/v1/posts/${id}/download`);
    result.isSucces = true;
    result.data = res;
  } catch (error) {
    console.log("error.responses", error.response);
    result.message = error?.response?.data;
  }
  return result;
};
export const profileData = async (id = "") => {
  let result = { isSucces: false };
  try {
    let res = await Axios.get(`api/v1/auth/profile`);
    result.isSucces = true;
    result.data = res;
  } catch (error) {
    console.log("error.responses", error.response);
    result.message = error?.response?.data;
  }
  return result;
};
export const roleData = async (id = "") => {
  let result = { isSucces: false };
  try {
    let res = await Axios.get(`api/v1/role`);
    result.isSucces = true;
    result.data = res;
  } catch (error) {
    console.log("error.responses", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const singlePost = async (id = "") => {
  let result = { isSucces: false };
  try {
    let res = await Axios.get(`api/v1/posts/${id}`);
    result.isSucces = true;
    result.data = res;
  } catch (error) {
    console.log("error.responses", error.response);
    result.message = error?.response?.data;
  }
  return result;
};
export const sharePost = async (id = "") => {
  let result = { isSucces: false };
  try {
    let res = await Axios.get(`api/v1/posts/${id}/share`);
    result.isSucces = true;
    result.data = res;
  } catch (error) {
    console.log("error.responses", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const mailerForReports = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(`/api/v1/mailer-report/summary-dau-mau`, data);
    result.isSucces = true;
    result.data = res.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const postViewCount = async (id = "") => {
  let result = { isSucces: false };
  try {
    let res = await Axios.get(`api/v1/posts/${id}/views`);
    result.isSucces = true;
    result.data = res;
  } catch (error) {
    console.log("error.responses", error.response);
    result.message = error?.response?.data;
  }
  return result;
};
export const postDelete = async (id = "") => {
  let result = { isSucces: false };
  try {
    let res = await Axios.get(`api/v1/posts/${id}/status`);
    result.isSucces = true;
    result.data = res;
  } catch (error) {
    console.log("error.responses", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const LeaderBoardDubaiQualifier = async (data) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.get(`/api/v1/leaderboard/get-dubai-qualifier`, data);
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

// export const LeaderBoardEntryPass = async (data) => {

//     let result = { isSucces: false }
//     try {
//         let res = await Axios.get(`/api/v1/leaderboard/get-founder-invitation`,{ headers: {"Authorization" : `Bearer ${data}`} });
//         result.isSucces = res.data.success;
//         result.data = res.data.data;
//     } catch (error) {
//         console.log('error.response', error.response);
//         result.message = error?.response?.data;
//     }
//     return result;
// }

// export const EntryPassConfirmation = async (data) => {
//     let result = { isSucces: false }
//     try {
//         let res = await Axios.post(`/api/v1/leaderboard/get-founder-confirmation`, data);
//         result.isSucces = res.data.success;
//         result.data = res.data.data;
//     } catch (error) {
//         console.log('error.response', error.response);
//         result.message = error?.response?.data;
//     }
//     return result;
// }

export const getWebinarData = async (data) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(`api/v1/gamification/webinar-dashboard`, data);
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};
export const getDailyWebinarData = async (data) => {
  let result = { isSucces: false };
  try {
    // let res = await Axios.post(`api/v1/gamification/daily-webinar-dashboard`,data);
    let res = await Axios.post(
      `api/v1/gamification/bot-launchpad-dashboard`,
      data
    );
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};
export const gettenDaysDataData = async (data) => {
  let result = { isSucces: false };
  try {
    // let res = await Axios.post(`api/v1/gamification/daily-webinar-dashboard`,data);
    let res = await Axios.post(`api/v1/home/ten-day-mba`, data);
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const billionaireWebStats = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(
      `/api/v1/gamification/billionaire-dashboard`,
      data
    );
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const dailyBillionaireWebStats = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(
      `/api/v1/gamification/daily-billionaire-dashboard`,
      data
    );
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const getElpRank = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.get(`/api/v1/elpdashboard/rm-list`, data);
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const getMbaUserDetails = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.get(`/api/v1/home/revenue-funnel`, data);
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const getMbaBatch = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.get(`/api/v1/home/batch`, data);
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const getMbaFunnel = async (data = {}) => {
  let result = { isSucces: false };
  try {
    let res = await Axios.post(`/api/v1/home/mba-funnel`, data);
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};

export const getBbImpactData = async (data) => {
  let result = { isSucces: false };
  try {
    // let res = await Axios.post(`api/v1/gamification/daily-webinar-dashboard`,data);
    let res = await Axios.post(`api/v1/home/bb-impact-dashboard`, data);
    result.isSucces = res.data.success;
    result.data = res.data.data;
  } catch (error) {
    console.log("error.response", error.response);
    result.message = error?.response?.data;
  }
  return result;
};
