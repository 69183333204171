import * as actionTypes from '../type';

const initialState = {
    addPostStateData:{},
    viewPostStateData:{},
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
            case actionTypes.SET_STATE_ADD_POST_DATA:
            return {
                ...state, addPostStateData: action.payload, loaded: true };

                case actionTypes.SET_STATE_VIEW_POSTS:
                    return {
                        ...state, viewPostStateData: action.payload, loaded: true };
                         case actionTypes.UPDATE_LIKE_DATA:
                    return {
                        ...state, viewPostStateData: action.payload, loaded: true };
            default:
            return state;
    }
}

export default reducer;
