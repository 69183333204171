import axios from 'axios';
const apiBaseUrl = process.env.REACT_APP_SERVER_URL;

const Axios = axios.create({
    baseURL: apiBaseUrl
});

//set token to user request.
/**
 * @param token set token to axiox
 */
export const setToken = token => {
    if (token) {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    } else {
        delete Axios.defaults.headers.common['Authorization'];
    }
}

export default Axios;

