export const SET_STATS_REVENU = 'SET_STATS_REVENU';
export const SET_IBC_REVENU = 'SET_IBC_REVENU';
export const SET_STATS_TREND_REVENU = 'SET_STATS_TREND_REVENU';
export const SET_IBC_TREND_REVENU = 'SET_IBC_TREND_REVENU';
export const SET_IBC_REVENU_OVERVIEW = 'SET_IBC_REVENU_OVERVIEW';
export const SET_STATS_REVENU_SUB_OVERVIEW = 'SET_STATS_REVENU_SUB_OVERVIEW';
export const SET_STATS_REVENU_OVERVIEW = 'SET_STATS_REVENU_OVERVIEW';
export const SET_DATE = 'SET_DATE';
export const CLEAR_DATE_CHANGED = "CLEAR_DATE_CHANGED";
export const SET_STATS_CP_REVENU_OVERVIEW = 'SET_STATS_CP_REVENU_OVERVIEW';
export const SET_ROLE = 'SET_ROLE';

// lead
export const SET_STATS_TREND_LEAD='SET_STATS_TREND_LEAD';
export const SET_STATS_LEAD_OVERVIEW='SET_STATS_LEAD_OVERVIEW';
export const SET_WEB_STATS_TREND_LEAD='SET_WEB_STATS_TREND_LEAD';
export const SET_WEB_STATS_LEAD_OVERVIEW='SET_WEB_STATS_LEAD_OVERVIEW';
export const SET_STATS_SELF_LEAD_OVERVIEW = 'SET_STATS_SELF_LEAD_OVERVIEW';
export const SET_LEAD_LOADED="SET_LEAD_LOADED";

// potetial
export const SET_STATS_POTENTIAL_COMMISSION='SET_STATS_POTENTIAL_COMMISSION'
export const SET_STATS_POTENTIAL_COMMISSION_OVERVIEW='SET_STATS_POTENTIAL_COMMISSION_OVERVIEW'

export const SET_WEB_STATS_POTENTIAL_COMMISSION='SET_WEB_STATS_POTENTIAL_COMMISSION'
export const SET_WEB_STATS_POTENTIAL_COMMISSION_OVERVIEW='SET_WEB_STATS_POTENTIAL_COMMISSION_OVERVIEW'

export const SET_STATS_SELF_POTENTIAL_COMMISSION_OVERVIEW='SET_STATS_SELF_POTENTIAL_COMMISSION_OVERVIEW'
export const SET_POTENTIAL_COMMISSION_LOADED='SET_POTENTIAL_COMMISSION_LOADED'


// EMI
export const SET_STATS_EMI='SET_STATS_EMI';
export const SET_STATS_EMI_OVERVIEW='SET_STATS_EMI_OVERVIEW';
export const SET_STATS_SELF_EMI_OVERVIEW='SET_STATS_SELF_EMI_OVERVIEW';
export const SET_EMI_LOADED='SET_EMI_LOADED';

// ibc onboard

export const SET_IBC_ONBORAD = 'SET_IBC_ONBORAD';


// DAU / MAU

export const SET_STATS_TREND_DAU = 'SET_STATS_TREND_DAU';
export const SET_STATS_OVERVIEW_DAU = 'SET_STATS_OVERVIEW_DAU';


// Webinar

export const SET_STATS_WEBINAR_DATA = 'SET_STATS_WEBINAR_DATA';
export const SET_STATS_WEBINAR_DAILY = 'SET_STATS_WEBINAR_DAILY';
export const SET_STATS_FUNNEL_DASHBOARD = 'SET_STATS_FUNNEL_DASHBOARD';
export const SET_STATS_WEBINAR_SOURCE = 'SET_STATS_WEBINAR_SOURCE';


//Leader board

export const SET_STATS_LEADER_BOARD_AIR = 'SET_STATS_LEADER_BOARD_AIR';
export const SET_STATS_LEADER_BOARD_TOP_TEN ='SET_STATS_LEADER_BOARD_TOP_TEN';
export const SET_STATS_GET_USER_RANK = 'SET_STATS_GET_USER_RANK';
export const SET_STATS_LEADER_BOARD_ZR = 'SET_STATS_LEADER_BOARD_ZR';
export const SET_STATS_CP_LIST = 'SET_STATS_CP_LIST';
export const SET_STATS_LEADER_BOARD_DUBAI_QUALIFIER = 'SET_STATS_LEADER_BOARD_DUBAI_QUALIFIER';


//Community Add posts
export const SET_STATE_ADD_POST_DATA = 'SET_STATE_ADD_POST_DATA';
export const SET_STATE_VIEW_POSTS = 'SET_STATE_VIEW_POSTS';
export const UPDATE_LIKE_DATA = 'UPDATE_LIKE_DATA';
export const SET_PROFILE = 'SET_PROFILE';
export const GET_PROFILE = 'GET_PROFILE';



// Download Reports

export const MAILER_FOR_REPORTS = 'MAILER_FOR_REPORTS';

// BB Coins

export const BB_COINS = 'BB_COINS';
export const BB_COINS_BANNER = 'BB_COINS_BANNER';
export const BB_COINS_CURRENT_OFFERS = 'BB_COINS_CURRENT_OFFERS';
export const BB_COINS_UPCOMING_OFFERS = 'BB_COINS_UPCOMING_OFFERS';
export const BB_COINS_HISTORY = 'BB_COINS_HISTORY';
export const BB_COINS_OFFERS_DETAILS = 'BB_COINS_OFFERS_DETAILS';
export const BB_COINS_COUPON_REDEEM = 'BB_COINS_COUPON_REDEEM';
export const BB_COINS_REDEEM_CODE = 'BB_COINS_REDEEM_CODE';