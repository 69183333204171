import * as actionTypes from '../type';

const initialState = {
    statsTrendPotentialCommision: {},
    statsPotentialCommissionOverview: {},
    webStatsTrendPotentialCommission: {},
    webStatsPotentialCommissionOverview: {},
    statsSelfPotentialCommissionOverview:{},
    loaded: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_STATS_POTENTIAL_COMMISSION:
            return { ...state, statsTrendPotentialCommision: action.payload };
        case actionTypes.SET_WEB_STATS_POTENTIAL_COMMISSION:
            return { ...state, webStatsTrendPotentialCommission: action.payload };
        case actionTypes.SET_WEB_STATS_POTENTIAL_COMMISSION_OVERVIEW:
            return { ...state, webStatsPotentialCommissionOverview: action.payload };
        case actionTypes.SET_STATS_POTENTIAL_COMMISSION_OVERVIEW:
            return { ...state, statsPotentialCommissionOverview: action.payload };
        case actionTypes.SET_STATS_SELF_POTENTIAL_COMMISSION_OVERVIEW:
            return { ...state, statsSelfPotentialCommissionOverview: action.payload };
        case actionTypes.SET_POTENTIAL_COMMISSION_LOADED:
            return { ...state, loaded: action.payload };
    
        default:
            return state;
    }
}

export default reducer;
