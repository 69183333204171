import * as actionTypes from '../type';

const initialState = {
    statsTrendLead: {},
    statsLeadOverview: {},
    webStatsTrendLead: {},
    webStatsLeadOverview: {},
    statsSelfLeadOverview:{},
    loaded: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_STATS_TREND_LEAD:
            return { ...state, statsTrendLead: action.payload };
        case actionTypes.SET_STATS_LEAD_OVERVIEW:
            return { ...state, statsLeadOverview: action.payload };
        case actionTypes.SET_WEB_STATS_TREND_LEAD:
                return { ...state, webStatsTrendLead: action.payload };
        case actionTypes.SET_WEB_STATS_LEAD_OVERVIEW:
            return { ...state, webStatsLeadOverview: action.payload };
        case actionTypes.SET_STATS_SELF_LEAD_OVERVIEW:
            return { ...state, statsSelfLeadOverview: action.payload };
        case actionTypes.SET_LEAD_LOADED:
            return { ...state, loaded: action.payload };
    
        default:
            return state;
    }
}

export default reducer;
