import moment from 'moment';
import * as actionTypes from '../type';

const initialState = {
    stateRevenu: {},
    ibcRevenu: {},
    stateTrendRevenu: {},
    webStatsTrendRevenue: {},
    webStatsRevenueSubOverview: {},
    ibcTrendRevenu: {},
    ibcRevenuOverview: {},
    stateRevenuOverview: {},
    stateCpRevenuOverview:{},
    selectedDate: (()=>{ if(Number(moment().format('DD')) <= 3 )
  {
    return [moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),moment().subtract(1, 'months').endOf('months').format('YYYY-MM-DD')]
  }else {
    return [moment().format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')]

  }})(),
    dateChanged: false,
    role: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_STATS_REVENU:
            return { ...state, stateRevenu: action.payload };
        case actionTypes.SET_IBC_REVENU:
                return { ...state, ibcRevenu: action.payload };
        case actionTypes.SET_STATS_TREND_REVENU:
            return { ...state, stateTrendRevenu: action.payload };
        case actionTypes.SET_STATS_REVENU_SUB_OVERVIEW:
                return { ...state, webStatsRevenueSubOverview: action.payload };
        case actionTypes.SET_IBC_REVENU_OVERVIEW:
            return { ...state, ibcRevenuOverview: action.payload };
        case actionTypes.SET_IBC_TREND_REVENU:
                return { ...state, ibcTrendRevenu: action.payload };
        case actionTypes.SET_STATS_REVENU_OVERVIEW:
            return { ...state, stateRevenuOverview: action.payload };
        case actionTypes.SET_STATS_CP_REVENU_OVERVIEW:
            return { ...state, stateCpRevenuOverview: action.payload };
        case actionTypes.SET_DATE:
            return { ...state, selectedDate: action.payload, dateChanged: true };
        case actionTypes.CLEAR_DATE_CHANGED:
            return { ...state, dateChanged: false };
        case actionTypes.SET_ROLE:
            return { ...state, role: action.payload };
        default:
            return state;
    }
}

export default reducer;
