import React, { useEffect } from 'react'
import queryString from 'query-string';
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../../http/Axios";
import './style.css';
import './media.css';
import { getofferDetails } from '../../http/ServiceApi';
import { BB_COINS_OFFERS_DETAILS } from '../../store/type';
import { useHistory } from 'react-router-dom';

export default function TmcClub(props) {

    let params = queryString.parse(props.location.search);
    let token = params.token;
    let newToken = token.split("/?")
    let offerId = newToken[1].split("=")[1]
    const history = useHistory();
    const dispatch = useDispatch();
    const apiData = useSelector(store => store?.coins);

    useEffect(() => {
        const offerDetails = async () => {
            const OffersData = await getofferDetails(offerId);
            if (OffersData.isSucces) {
                dispatch({ type: BB_COINS_OFFERS_DETAILS, payload: OffersData.data });
            }
        }
        if (params.token) {
            setToken(newToken[0]);
            offerDetails();
        }
        else {
            props?.history?.push('error');
        }
    }, [])
    return (
        <>
           {/* <div className="top_back offer_details"> 
                <a href="#"><i class="fa fa-angle-left" onClick={() => history.goBack()} aria-hidden="true"></i> &nbsp;Offer Details</a>
                </div> */}
            <section className="tmc_club">
                <div className="text-center"><img src={apiData?.getOfferDetails?.offerbanner} className="img-fluid" alt="" style={{ width: "100%" }} /> </div>
                <p className='mt-2'><b>{apiData?.getOfferDetails?.title}</b></p>
                {
                    apiData?.getOfferDetails?.description?.map((item) => {
                        return <ul className="blue_card_section">
                            <li>{item}</li>
                        </ul>
                    })
                }
                {
                    apiData?.getOfferDetails?.processRequest === "No" ? "" :
                        <>
                            <div className="btm_btns">
                                {apiData?.getOfferDetails?.showRedeemBtn && <> <a href={`/redeem-coupon/?token=${token}`} className="orange">Redeem for {apiData?.getOfferDetails?.redeemCoins} Coins</a> </>}
                                {apiData?.getOfferDetails?.showNeedBtn && <> <a href="#" className="grey">Need {apiData?.getOfferDetails?.needCoins} Coins To Redeem</a> </>}
                            </div>
                        </>
                }

            </section>
        </>
    )
}
