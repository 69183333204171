import React, { useEffect } from 'react'
import queryString from 'query-string';
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../../http/Axios";
import './style.css';
import './media.css';
import { BB_COINS_COUPON_REDEEM, BB_COINS_REDEEM_CODE } from '../../store/type';
import { getRedeemCode, getRedeemCoupons } from '../../http/ServiceApi';
import { useHistory, useParams } from 'react-router-dom';

export default function ThankYou(props) {
    let params = queryString.parse(props.location.search);
    let token = params.token;
    let newToken = token.split("/?")
    let offerId = newToken[1].split("=")[1]
    let orderId = newToken[2].split("=")[1]
    const dispatch = useDispatch();
    const apiData = useSelector(store => store?.coins);

    useEffect(() => {
        const redeemCode = async () => {
            const payload = {
                orderId: orderId,
                offerId: offerId
            }

            const redeemCode = await getRedeemCode(payload);
            if (redeemCode.isSucces) {
                dispatch({ type: BB_COINS_REDEEM_CODE, payload: redeemCode.data });
            }
        }
        if (params.token) {
            setToken(newToken[0]);
            redeemCode();
        }
        else {
            props?.history?.push('error');
        }
    }, [])

    return (
        <>
            <section className="congratulations">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-12">
                            <div className="confetti">
                                <a href={`/bb-coins/?token=${newToken[0]}`} class="" style={{
                                    "position": "absolute",
                                    "top": "50px",
                                    "right": "10%",
                                    "fontSize": "30px"
                                }}><i class="fa fa-times" aria-hidden="true"></i>
                                </a>
                                <div className="clrs text-center">
                                    <img src="https://images.badabusiness.com/bbapp/images/bb-coins/confetti-BG.jpg" alt="" />
                                </div>
                                <div className="text-center">
                                    <img src="https://images.badabusiness.com/bbapp/images/bb-coins/user.png" alt="" />
                                </div>
                                <h4>Congratulation!!</h4>
                                <p className="text-center" style={{"fontSize": "12px", color: "#000", fontWeight: "500", lineHeight: "20px"}}>It is a long established fact that a reader will be distracted by the readable</p>
                                <h6>{apiData?.getRedeemCode?.redeemCode}</h6>
                                <a href={`/bb-coins/?token=${newToken[0]}`} class="btn btn-sm" style={{ "display": "block", background: "#ffb060", "margin": "20px 10px" }}>Back to home page</a>
                                <p className="text-center" style={{"fontSize": "12px", color: "#000", fontWeight: "500", lineHeight: "20px"}} >*Terms and Conditions Apply</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
