import * as actionTypes from '../type';

const initialState = {
    data: {},
    loaded: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_IBC_ONBORAD:
            return { ...state, data: action.payload, loaded: true };
    
        default:
            return state;
    }
}

export default reducer;
