import * as actionTypes from '../type';

const initialState = {
    profile:{}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
            case actionTypes.SET_PROFILE:
            return {
                ...state, profile:action.payload, loaded: true };

                case actionTypes.GET_PROFILE:
                    return state

            default:
            return state;
    }
}

export default reducer;
